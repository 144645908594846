export const VALID_GUESSES_7 = ['abrudan',
'abzalov',
'acevedo',
'achondo',
'adamson',
'addison',
'agarwal',
'aguirre',
'akiyama',
'akkoyun',
'alcaraz',
'alemany',
'alkotop',
'almeida',
'alvarez',
'ambrozy',
'amicuzi',
'andrade',
'andreev',
'andrews',
'andujar',
'anselmo',
'anthrop',
'anttila',
'aragone',
'arakawa',
'arevalo',
'arnaldi',
'arneodo',
'astorga',
'aubriot',
'augenti',
'avidzba',
'ayukawa',
'azoides',
'babelis',
'backman',
'bagaric',
'bajagic',
'baldoni',
'banthia',
'baquero',
'barbosa',
'barclay',
'bardzin',
'barnett',
'barrena',
'barrere',
'barrett',
'barrios',
'barthel',
'bartone',
'batagin',
'batista',
'bayldon',
'becerra',
'beckham',
'beckley',
'becuzzi',
'bellier',
'benamar',
'bendeck',
'berdych',
'berezov',
'bergevi',
'berlocq',
'bernabe',
'bernard',
'bertens',
'bertola',
'bertran',
'bertsch',
'bessire',
'bhambri',
'bhosale',
'bianchi',
'bierman',
'bilardo',
'binding',
'birrell',
'blakely',
'blekher',
'blokhin',
'boehler',
'boehner',
'boisson',
'bojovic',
'bolardt',
'bolelli',
'bolotov',
'bolsova',
'bombara',
'bonadio',
'bonardi',
'bonnaud',
'bopanna',
'borroni',
'boserup',
'bosnjak',
'botezan',
'boualem',
'bougrat',
'boulais',
'boulter',
'bouquet',
'bourcet',
'boureau',
'bourgue',
'bouzige',
'boychuk',
'bozovic',
'bradley',
'bramajo',
'brandao',
'braynin',
'brengle',
'brigida',
'broadus',
'brodney',
'brosset',
'brouwer',
'brunold',
'bulgaru',
'bullani',
'bullard',
'burdina',
'burnett',
'burrage',
'cabrera',
'cagnina',
'calvano',
'camacho',
'camargo',
'camilli',
'campana',
'campino',
'capacci',
'capalbo',
'capurro',
'carraro',
'caruana',
'cascino',
'casucci',
'cataldi',
'cazaban',
'cazeaux',
'cekirge',
'celebic',
'centeno',
'cernoch',
'chahoud',
'chekhov',
'cheruku',
'chidekh',
'chikami',
'chirico',
'ciccone',
'cirotte',
'cirstea',
'clayton',
'clément',
'cobolli',
'coleman',
'collard',
'collens',
'collier',
'colling',
'collins',
'colombo',
'congcar',
'coppini',
'coppola',
'corbett',
'cottino',
'courier',
'crawley',
'cubelic',
'culibrk',
'curovic',
'dadaciu',
'dagnino',
'daniell',
'daniels',
'danilov',
'dapkute',
'darderi',
'dartron',
'dascalu',
'davydov',
'decamps',
'deckers',
'delaney',
'delfino',
'delgado',
'dellien',
'denisov',
'denolly',
'dentoni',
'devidze',
'dijkman',
'djoubri',
'doering',
'dominko',
'donders',
'donskoy',
'douglas',
'doukhan',
'doumbia',
'dourado',
'dragoni',
'draheim',
'droguet',
'durguti',
'dzumhur',
'eisinga',
'eleveld',
'ellouck',
'elsayed',
'emhardt',
'enqvist',
'eraydin',
'erhardt',
'erjavec',
'escobar',
'eshmade',
'esquici',
'estable',
'estevao',
'estevez',
'eubanks',
'falconi',
'falkner',
'fallert',
'fancutt',
'fanning',
'fantini',
'farulla',
'fawcett',
'fayziev',
'federer',
'felline',
'ferrara',
'ferrari',
'ferrero',
'fetecau',
'fichman',
'filatov',
'fognini',
'folliot',
'fonseca',
'fordham',
'fortuna',
'fourlis',
'frawley',
'friberg',
'frolova',
'fufygin',
'furness',
'gabarro',
'gabueva',
'gadecki',
'gadient',
'gaggini',
'galovic',
'gambill',
'gambogi',
'garcian',
'garland',
'gasquet',
'gautier',
'gauvain',
'gavrila',
'gemovic',
'gennaro',
'georges',
'gerlach',
'gervais',
'ghasemi',
'ghorbel',
'gibbens',
'giddens',
'gigante',
'gilbert',
'gimenez',
'ginepri',
'giovine',
'giraldo',
'girault',
'girelli',
'gleason',
'gleeson',
'glushko',
'godsick',
'goerges',
'golubev',
'golubic',
'gorlats',
'grabher',
'graovac',
'greiner',
'grenier',
'groener',
'gromley',
'gryniuk',
'gueydan',
'guinard',
'gumulya',
'hajkova',
'halemai',
'hamaliy',
'hansson',
'hanzlik',
'hartono',
'haskins',
'hatouka',
'hayashi',
'hedrzak',
'henning',
'herbert',
'hermans',
'hidalgo',
'hiltzik',
'hindova',
'hirales',
'hohmann',
'homberg',
'hontama',
'hornung',
'horvath',
'huesler',
'humbert',
'hurkacz',
'hurrion',
'husaric',
'ianchuk',
'iannini',
'ibrahim',
'ignatik',
'igoshin',
'imamura',
'ingarao',
'inserra',
'isomura',
'istomin',
'ivanova',
'ivashka',
'jachuck',
'jackson',
'jacquet',
'jandric',
'janssen',
'janvier',
'jenicek',
'jenkins',
'jimenez',
'jocovic',
'johnson',
'joncour',
'jonsson',
'jorovic',
'jurajda',
'kajevic',
'kaldawi',
'kalieva',
'kamynin',
'kapadia',
'kapiris',
'kardava',
'karimov',
'karlova',
'kawachi',
'kazakov',
'kessler',
'khomich',
'khotkov',
'kikuchi',
'kissell',
'kiuamov',
'klaasen',
'klincov',
'klopper',
'knutson',
'koaykul',
'koelzer',
'koepfer',
'koklina',
'konecny',
'konings',
'koolhof',
'kopriva',
'korolev',
'korovin',
'kostova',
'kostyuk',
'kotecha',
'kovalik',
'kovinic',
'kozarov',
'krainer',
'krainik',
'krajcik',
'kraleva',
'kratzer',
'krueger',
'krumich',
'krutykh',
'kuearum',
'kuerten',
'kulikov',
'kumaran',
'kumkhum',
'kuprina',
'kuzmova',
'kvitova',
'kyrgios',
'labbene',
'labrana',
'lacasse',
'ladhani',
'lajovic',
'lammons',
'lansere',
'larsson',
'laurent',
'lavagno',
'lawless',
'lazarov',
'lazdins',
'lebedev',
'leconte',
'ledesma',
'lehecka',
'leikola',
'lemstra',
'leonard',
'lescure',
'lesniak',
'leykina',
'lindell',
'linette',
'lisicki',
'livianu',
'lobanov',
'loefman',
'lomakin',
'lootsma',
'lorenzi',
'lottner',
'luikham',
'luncanu',
'maarten',
'maccari',
'maclean',
'magadan',
'malisse',
'maloney',
'manafov',
'manasse',
'mandlik',
'manekas',
'manyoma',
'marcora',
'mariani',
'mariona',
'marques',
'marrero',
'martins',
'massara',
'matoula',
'matsuda',
'mayotte',
'mazzoni',
'mcbride',
'mcenroe',
'mcnally',
'mdlulwa',
'meister',
'melgers',
'mendoza',
'menezes',
'mercier',
'mertens',
'michnev',
'miedler',
'miklova',
'millman',
'minakov',
'minarik',
'minella',
'mirgone',
'mishina',
'mishkin',
'misolic',
'misrahi',
'mitjana',
'moeller',
'moghini',
'mohamad',
'mohamed',
'molteni',
'monclus',
'monette',
'monfils',
'moraing',
'morales',
'morgina',
'moroder',
'morolli',
'moundir',
'mousley',
'mpukusa',
'mubarak',
'muchova',
'mueller',
'mugelli',
'mujakic',
'muniyan',
'muntean',
'murgett',
'murillo',
'murtaza',
'musetti',
'mushika',
'mutilba',
'myagkov',
'nagoudi',
'nareyka',
'narmont',
'navarro',
'nazaruk',
'nedelko',
'negritu',
'nejedly',
'nenwani',
'newborn',
'nhavene',
'nicault',
'nicosia',
'niedner',
'niesten',
'nijboer',
'nikitin',
'nitture',
'noguchi',
'noskova',
'novikov',
'ochsner',
'ocleppo',
'okagaki',
'okamura',
'okonkwo',
'oprandi',
'oradini',
'ortenzi',
'osborne',
'osuigwe',
'ozdemir',
'ozernoy',
'ozerova',
'ozolins',
'pacheco',
'padilla',
'pahlett',
'palazov',
'palecek',
'paliska',
'palkina',
'palovic',
'panaino',
'paolini',
'parenti',
'parnaby',
'partaud',
'pasqual',
'passaro',
'paulson',
'pauwels',
'pavlova',
'pazicky',
'pearson',
'pecotic',
'penzlin',
'pereira',
'peschke',
'petrone',
'petrova',
'pfanner',
'pfeifer',
'pichler',
'pigossi',
'pioline',
'piraino',
'pjontek',
'pohjola',
'pointon',
'pokorny',
'polacek',
'polasek',
'polmans',
'ponchet',
'ponomar',
'pontico',
'ponwith',
'popescu',
'popovic',
'popyrin',
'portela',
'potenza',
'pouille',
'pourroy',
'pozzani',
'prachar',
'pradova',
'preston',
'presuhn',
'prinoth',
'prizmic',
'proctor',
'purcell',
'quartey',
'querrey',
'quesada',
'quigley',
'qureshi',
'radisic',
'ragazzi',
'ramirez',
'ratniuk',
'raymond',
'reguant',
'rehacek',
'reitano',
'rengifo',
'revelli',
'reymond',
'ribaldi',
'ribeiro',
'riffice',
'rizzuti',
'roberts',
'robredo',
'roddick',
'rodenas',
'rodesch',
'roggero',
'rollins',
'romboli',
'rompies',
'rondoni',
'rottoli',
'roumane',
'rousset',
'rouvala',
'rouvroy',
'ruggeri',
'rungkat',
'ruppert',
'rushiti',
'rybakov',
'sabanin',
'sabanov',
'sadaoui',
'safonov',
'sahtali',
'sakkari',
'salazar',
'salgado',
'salkova',
'salomon',
'sampras',
'samudio',
'sanchez',
'sanders',
'santoro',
'saraiva',
'saritas',
'sarpola',
'sartori',
'sauvant',
'savchuk',
'savelev',
'saville',
'scaglia',
'schinas',
'schmidt',
'schmitz',
'schoofs',
'schroll',
'schulte',
'schultz',
'schuurs',
'sculley',
'searant',
'seateun',
'sebesta',
'seemann',
'seibold',
'seifert',
'sekulic',
'semmler',
'serrano',
'setodji',
'sewanou',
'shaaban',
'shaffer',
'shelton',
'shimizu',
'shokeir',
'sibanda',
'sibille',
'sigouin',
'simakin',
'simonit',
'simpson',
'simunyu',
'siskova',
'sklenka',
'skupski',
'smirnov',
'smyczek',
'snitari',
'solberg',
'solomon',
'spadola',
'spasibo',
'spencer',
'spiteri',
'srbljak',
'stadler',
'stalder',
'statham',
'stearns',
'stefani',
'steiner',
'stevens',
'stewart',
'stodder',
'stoliar',
'stollar',
'stolmar',
'struvay',
'subhash',
'sugnaux',
'susanto',
'suvrijn',
'svrcina',
'swiatek',
'sziedat',
'szintai',
'szostak',
'tabacco',
'tabrizi',
'taczala',
'talcott',
'talluri',
'tanasie',
'tatarus',
'tearney',
'tepavac',
'tepmahc',
'terziev',
'thommen',
'thomson',
'thurner',
'tomescu',
'torebko',
'tortora',
'tosetto',
'traskin',
'trinker',
'trismen',
'trocker',
'troicki',
'tsoneva',
'tubello',
'turkmen',
'turknas',
'tyurnev',
'udvardy',
'ukolova',
'valente',
'valkusz',
'vallejo',
'vardhan',
'vasilev',
'vatutin',
'vazquez',
'velotti',
'ventura',
'verbeek',
'verdier',
'vergara',
'verhaar',
'vernier',
'versier',
'vescovi',
'vesnina',
'vickery',
'vilardo',
'virgili',
'vismane',
'vlckova',
'vliegen',
'voegele',
'volfson',
'volodko',
'voronin',
'vukovic',
'vuradin',
'wacanno',
'wallace',
'wallart',
'walters',
'waltert',
'webster',
'wehnelt',
'wessels',
'whiting',
'whittle',
'william',
'winkler',
'withrow',
'witmeur',
'woerner',
'woldeab',
'wozniak',
'yashina',
'yeshaya',
'youzhny',
'yudanov',
'zaitcev',
'zamurri',
'zanotti',
'zarazua',
'zarycka',
'zelenay',
'zhurbin',
'ziegann',
'zivotic',
'zvonaru']

export const VALID_GUESSES_6 = [
'abanda',
'abazov',
'abbrat',
'aboian',
'acosta',
'acquah',
'adamek',
'adnane',
'agassi',
'ahouda',
'aikawa',
'ajavon',
'alegre',
'alimli',
'alkaya',
'alosha',
'altuna',
'alvisi',
'amoyal',
'angele',
'ansari',
'anshba',
'aoyama',
'arango',
'araujo',
'arcila',
'arends',
'armaah',
'artnak',
'ashley',
'asrawy',
'astete',
'atmane',
'atturu',
'badawi',
'badosa',
'bagnis',
'baiant',
'baindl',
'bakshi',
'balaji',
'balazs',
'banzer',
'baravi',
'barkai',
'barnat',
'barona',
'barros',
'basing',
'baskov',
'baszak',
'batlle',
'battle',
'bayard',
'baykal',
'beauge',
'beccio',
'bechri',
'becker',
'bedene',
'bekefi',
'bekker',
'bektas',
'bellis',
'bencic',
'bendre',
'benito',
'benoit',
'bergen',
'berger',
'bernal',
'besada',
'bester',
'bhasin',
'bhatia',
'bilgin',
'biolay',
'blanch',
'bobrov',
'bocchi',
'bodmer',
'boerma',
'bogdan',
'boitan',
'bokova',
'bolton',
'bondar',
'borges',
'borras',
'borsan',
'borsos',
'bossel',
'brands',
'brglez',
'briand',
'britez',
'britto',
'broady',
'brogan',
'bronka',
'broska',
'brozda',
'brymer',
'buayam',
'bublik',
'bugnon',
'burdet',
'burger',
'burnel',
'busoms',
'butler',
'butsch',
'cabral',
'cachin',
'caldes',
'camara',
'canter',
'caripi',
'carter',
'caruso',
'casays',
'castro',
'catani',
'catini',
'cazaux',
'cengiz',
'cepede',
'ceschi',
'chanta',
'chardy',
'chavez',
'chazal',
'cheong',
'chepik',
'chiang',
'chiesa',
'chlpac',
'chopra',
'chuang',
'chvets',
'clarke',
'clavel',
'clezar',
'coelho',
'colson',
'connor',
'conway',
'cooper',
'corino',
'corley',
'cornet',
'corwin',
'cosnet',
'crespi',
'cressy',
'crivoi',
'cuenin',
'cuevas',
'cundom',
'custic',
'cutuli',
'dahiya',
'dalina',
'daljev',
'daniel',
'darcis',
'darras',
'davies',
'dawani',
'delage',
'delmas',
'dembek',
'derdoy',
'desein',
'detiuc',
'devald',
'devaux',
'diallo',
'diarra',
'djokic',
'doldan',
'donati',
'donski',
'dormet',
'dougaz',
'downey',
'draper',
'drazic',
'drinic',
'drummy',
'duerst',
'dunbar',
'duncan',
'dussin',
'echazu',
'edberg',
'edmund',
'eikeri',
'elamin',
'elbaba',
'elkadi',
'enache',
'endara',
'eremin',
'erhard',
'erhart',
'erlich',
'errani',
'ersoez',
'escudé',
'evrard',
'fabian',
'failla',
'faivre',
'falcao',
'farbas',
'farjat',
'farmer',
'farren',
'fattar',
'faucon',
'favero',
'fayard',
'feigin',
'fekete',
'fekiac',
'fellin',
'ferrer',
'fiacan',
'fields',
'figlia',
'fillat',
'fiquet',
'flores',
'florig',
'fomina',
'forbes',
'forget',
'fortin',
'fouche',
'fowler',
'franco',
'freund',
'friant',
'frinzi',
'frunza',
'fukuda',
'furuya',
'gabric',
'gailis',
'gakhov',
'galdos',
'galiev',
'gamble',
'garcia',
'gaston',
'gatica',
'gaubas',
'gaware',
'gecsek',
'geerts',
'geller',
'gengel',
'gerald',
'gerini',
'gibson',
'gillan',
'giorgi',
'giotis',
'girard',
'giunta',
'giusca',
'glantz',
'glatch',
'glinka',
'goffin',
'gombos',
'goveas',
'graham',
'grassi',
'greven',
'grewal',
'grills',
'gruber',
'guedes',
'gulbis',
'guttau',
'haliak',
'hamner',
'hampel',
'handel',
'hansen',
'harper',
'harris',
'hassan',
'hassey',
'hasson',
'hazawa',
'heisen',
'helgoe',
'heller',
'hemery',
'henman',
'hercog',
'hering',
'herman',
'hersey',
'hertel',
'hewitt',
'heyman',
'hibino',
'hingis',
'hirata',
'hjorth',
'hodkin',
'holmes',
'hopper',
'horvat',
'hosogi',
'hosoki',
'hossam',
'houkes',
'hozumi',
'hubner',
'hunter',
'hussey',
'ingale',
'inglis',
'inglot',
'iraira',
'isaeva',
'iturbe',
'iturra',
'ivanov',
'jabeur',
'jacobs',
'jadhav',
'jaeger',
'jansen',
'jasika',
'jaziri',
'jebavy',
'jebawy',
'jebens',
'jensen',
'jermar',
'johann',
'juarez',
'junaid',
'jurman',
'kahlke',
'kajuru',
'kalava',
'kalita',
'kamath',
'kanepi',
'kannan',
'kavcic',
'keller',
'kempen',
'kennel',
'kerber',
'khalil',
'khirin',
'kicker',
'kiefer',
'kielan',
'kilani',
'kimchi',
'kimoto',
'kinard',
'kirkin',
'kirkov',
'kittay',
'klasen',
'klegou',
'klepac',
'klizan',
'knight',
'knowle',
'kobelt',
'kobori',
'koenig',
'koller',
'kommer',
'konjuh',
'kosaka',
'koskel',
'kossek',
'kostic',
'kotzen',
'kozaki',
'kozlov',
'kranic',
'kremen',
'krstev',
'krstic',
'krstin',
'kruger',
'krunic',
'krywoj',
'kubler',
'kucova',
'kuczer',
'kummel',
'kupres',
'kurata',
'kuwata',
'kypson',
'lagaev',
'lamens',
'langmo',
'lapalu',
'larrea',
'larwig',
'lavino',
'lawson',
'legout',
'leshem',
'leslie',
'levine',
'lincer',
'lingua',
'lipsky',
'lister',
'little',
'liucci',
'llanes',
'lohoff',
'lokoli',
'lollia',
'lorens',
'lovett',
'lovric',
'lucero',
'lucini',
'lukacs',
'lustig',
'machac',
'macias',
'manuel',
'marach',
'marcon',
'marcos',
'mareen',
'marfia',
'marino',
'markes',
'martic',
'martin',
'masini',
'maslau',
'maslov',
'masuri',
'mateas',
'matsui',
'maxted',
'maytin',
'mbithi',
'mcadoo',
'mccabe',
'mchale',
'mchugh',
'mcphee',
'meehan',
'meguro',
'mektic',
'meliss',
'melnic',
'melzer',
'mendez',
'mensik',
'meraut',
'mergea',
'merino',
'miceli',
'michel',
'mihail',
'mikrut',
'mikula',
'miller',
'millot',
'minnen',
'misasi',
'mitsui',
'modica',
'molcan',
'molina',
'molino',
'monaco',
'monday',
'monnet',
'monnot',
'monova',
'monroe',
'monroy',
'montes',
'montez',
'montsi',
'monzon',
'morais',
'morant',
'moreno',
'morgan',
'morita',
'moriya',
'morlet',
'moroni',
'morton',
'mossur',
'mounir',
'moutet',
'moyano',
'moysan',
'mrdeza',
'mridha',
'muamba',
'mukund',
'muller',
'munafo',
'munhoz',
'murali',
'murphy',
'murray',
'muzaev',
'myneni',
'nabiev',
'nagata',
'nallon',
'navone',
'nepliy',
'nestor',
'neubau',
'newman',
'nguyen',
'nikles',
'njiric',
'noikor',
'norrie',
'noskin',
'nupbay',
'ollert',
'onclin',
'onishi',
'ontica',
'opelka',
'orange',
'orlova',
'ornago',
'orpana',
'ortega',
'ortlip',
'oshino',
'osorio',
'oswald',
'ouahab',
'ouakaa',
'palosi',
'paluch',
'panova',
'paquet',
'parker',
'pasini',
'paszek',
'patael',
'patino',
'patten',
'paulus',
'pavlou',
'pawlak',
'paykoc',
'payola',
'pedone',
'pegula',
'peliwo',
'peluso',
'perper',
'perrin',
'peters',
'petrov',
'peyrot',
'pielet',
'pierce',
'pierre',
'pietri',
'pigato',
'pirtac',
'pisane',
'pislak',
'plazas',
'plentz',
'podzus',
'poling',
'poljak',
'ponder',
'postay',
'poulos',
'powell',
'prasad',
'prince',
'puskar',
'pysson',
'qostal',
'quadre',
'quayle',
'quinzi',
'quiroz',
'rabman',
'raisma',
'rajesh',
'rajski',
'raonic',
'rapolu',
'ravita',
'rawson',
'raynel',
'reasco',
'reayer',
'reboul',
'refaat',
'renard',
'reuter',
'ribero',
'richey',
'rieder',
'rihane',
'rincon',
'rivera',
'rivkin',
'robert',
'robles',
'robson',
'rocens',
'rodari',
'rodeia',
'rodina',
'roemer',
'rogers',
'roglan',
'roldan',
'romero',
'romios',
'rother',
'rubini',
'rublev',
'ruland',
'rumler',
'ruzgas',
'sabino',
'sachko',
'safwat',
'saitoh',
'salden',
'sallay',
'salman',
'salmon',
'salton',
'samaha',
'samrej',
'samuel',
'sancic',
'sanesi',
'santos',
'sarkar',
'sarouk',
'satral',
'savkin',
'scelzi',
'schaer',
'schech',
'schell',
'schmid',
'schnur',
'scholl',
'schott',
'schunk',
'scotty',
'searle',
'seelig',
'seeman',
'seguel',
'segura',
'seidel',
'sekhar',
'semjan',
'sengul',
'serban',
'serena',
'setkic',
'sewing',
'shabaz',
'shamma',
'sharan',
'sharma',
'sheehy',
'sherif',
'shinde',
'sidney',
'sierra',
'siimar',
'silagy',
'silich',
'simion',
'simone',
'simons',
'sinner',
'skatov',
'skugor',
'slavic',
'sleeth',
'snigur',
'soares',
'somani',
'sonego',
'sonmez',
'sonoda',
'sorger',
'soyler',
'spadea',
'spears',
'sperle',
'sproch',
'squire',
'stagno',
'stamat',
'stanek',
'stepan',
'stepin',
'stevic',
'stipic',
'stoica',
'stokke',
'stosur',
'stouff',
'stoupe',
'stoute',
'strode',
'stroem',
'struff',
'stuart',
'styler',
'suarez',
'sueoka',
'sugita',
'sumova',
'sunish',
'suntic',
'suresh',
'suzuki',
'svajda',
'sweeny',
'symons',
'sysoev',
'tabilo',
'tacchi',
'taddia',
'tagata',
'tajima',
'takeda',
'talaba',
'tanaka',
'tanuma',
'tarvet',
'tauson',
'taylor',
'telles',
'tewari',
'thamma',
'thandi',
'thanos',
'thayne',
'tholey',
'thomas',
'tiafoe',
'tiglea',
'timsit',
'todoni',
'tokuda',
'tomova',
'topcic',
'torres',
'torski',
'tresca',
'triebe',
'troche',
'troost',
'trouve',
'trunov',
'tsonga',
'turati',
'turner',
'tzicas',
'uchida',
'uesugi',
'urbina',
'urgesi',
'urinov',
'uryson',
'uvarov',
'uzakov',
'valdes',
'valeov',
'vaneva',
'vankan',
'vargas',
'varney',
'velcea',
'verbin',
'vereau',
'verner',
'vernet',
'vesely',
'viiala',
'vilaro',
'villar',
'villet',
'visker',
'vittar',
'voisin',
'wagner',
'wakita',
'walder',
'walker',
'walkin',
'walkow',
'wallin',
'walton',
'watson',
'weindl',
'welker',
'wenger',
'werner',
'westby',
'willis',
'winter',
'wirges',
'witten',
'wojcik',
'wojnar',
'wuarin',
'wunner',
'yamada',
'younes',
'yuzuki',
'zahraj',
'zanada',
'zapsky',
'zavala',
'zeleva',
'zerulo',
'zhibul',
'zhukov',
'zhuoma',
'zonder',
'zotova',
'zueger',
'zusman',
'zverev'
]

export const VALID_GUESSES_5 = ['adams',
'added',
'adler',
'ahmed',
'aiava',
'aiche',
'akita',
'akler',
'alban',
'albie',
'albot',
'alkio',
'allaf',
'allen',
'alves',
'amiri',
'anand',
'anane',
'andre',
'anger',
'appel',
'araya',
'arias',
'arinc',
'asaba',
'atawo',
'aulia',
'ayeni',
'azziz',
'baadi',
'babel',
'babic',
'babos',
'baese',
'bahri',
'bains',
'baird',
'baker',
'baldi',
'balla',
'banes',
'banks',
'barad',
'barbu',
'barki',
'baron',
'barty',
'barun',
'basic',
'basso',
'bauvy',
'beale',
'beghi',
'behar',
'benya',
'beran',
'beren',
'bergs',
'berne',
'betov',
'bhunu',
'binda',
'biran',
'black',
'blake',
'block',
'bokor',
'bolla',
'boltz',
'bonic',
'bonzi',
'borba',
'bordo',
'boren',
'boros',
'borza',
'bosio',
'bowen',
'boyer',
'brace',
'brady',
'braun',
'breut',
'brito',
'brkic',
'broom',
'brown',
'bruce',
'brumm',
'bruna',
'bryan',
'bryde',
'bucan',
'bucsa',
'budic',
'bueno',
'bulte',
'bulus',
'burel',
'butts',
'bybel',
'bynoe',
'cacao',
'cacic',
'cadar',
'calzi',
'campo',
'camus',
'carle',
'carou',
'carpi',
'casso',
'catry',
'cayre',
'ccuno',
'cenoz',
'cepel',
'cerny',
'cesar',
'chang',
'chong',
'chung',
'cigna',
'cilic',
'ciuca',
'clark',
'cline',
'cloes',
'closs',
'cohen',
'colak',
'coman',
'congu',
'copil',
'coria',
'coric',
'cossu',
'court',
'craig',
'cretu',
'cross',
'csabi',
'curmi',
'dabah',
'dabic',
'daley',
'damas',
'damov',
'davis',
'deari',
'debru',
'delai',
'demin',
'desai',
'diyas',
'djere',
'dlimi',
'dobry',
'dodig',
'dodin',
'donev',
'doria',
'draxl',
'dunne',
'duran',
'duval',
'ebden',
'eguez',
'ehara',
'ehrat',
'einig',
'ekani',
'elbaz',
'elgin',
'elias',
'ellis',
'emesz',
'eqbal',
'erler',
'errey',
'evans',
'every',
'ewijk',
'ezzat',
'fahey',
'fajta',
'falck',
'farah',
'faria',
'fatic',
'feder',
'feitt',
'fenty',
'ferro',
'filip',
'flink',
'foley',
'folts',
'fomin',
'fonio',
'fonte',
'force',
'forti',
'forys',
'fouad',
'frahn',
'frech',
'fritz',
'frosa',
'frost',
'gaber',
'galan',
'galfi',
'galik',
'galka',
'galus',
'gamiz',
'ganga',
'garin',
'garza',
'gates',
'gauff',
'geens',
'genov',
'gerch',
'geuer',
'gibbs',
'gille',
'ginat',
'giner',
'giron',
'glvac',
'gobat',
'gobbi',
'goetz',
'gomaa',
'gomes',
'gomez',
'gooch',
'graca',
'grant',
'green',
'gregg',
'greif',
'grimm',
'gross',
'groth',
'gulin',
'gusev',
'haase',
'habib',
'hajek',
'halep',
'halys',
'hamou',
'hance',
'hands',
'hardt',
'heger',
'heide',
'helmi',
'hesse',
'hives',
'hoang',
'hobbs',
'hodor',
'hoedt',
'hofer',
'holis',
'hondo',
'horak',
'horve',
'hough',
'hoyos',
'hsieh',
'huang',
'husak',
'ianin',
'ibbou',
'idoko',
'ilhan',
'ilkel',
'inoue',
'ionel',
'isner',
'jakic',
'james',
'janis',
'jarry',
'javia',
'jecan',
'jelen',
'jeong',
'jiang',
'jianu',
'johns',
'jokic',
'jomby',
'jones',
'jorge',
'jovic',
'joyce',
'juhas',
'jurak',
'juric',
'jurna',
'justo',
'juvan',
'kadhe',
'kalva',
'kamke',
'kanar',
'kanev',
'kania',
'karol',
'katic',
'keist',
'kekez',
'kelly',
'kenin',
'keren',
'kiger',
'kiick',
'kimhi',
'kirci',
'klahn',
'klein',
'klier',
'knaff',
'knoll',
'knoop',
'kohen',
'koike',
'kolar',
'komar',
'konov',
'konta',
'korda',
'kotov',
'koval',
'krall',
'kraus',
'kriek',
'krohn',
'krolo',
'kubka',
'kubot',
'kudla',
'kuhar',
'kumar',
'kwong',
'lacko',
'lahey',
'laine',
'lajal',
'lakat',
'lampl',
'lanca',
'lanik',
'lapko',
'largo',
'lazar',
'lazov',
'leduc',
'leite',
'lekaj',
'lendl',
'lerby',
'leuch',
'lewis',
'liang',
'licea',
'lilov',
'liska',
'lohan',
'lopez',
'lozan',
'luini',
'lukas',
'lulli',
'luria',
'lysov',
'madan',
'maden',
'mader',
'mager',
'maher',
'mahut',
'maier',
'maiga',
'major',
'maker',
'malan',
'malik',
'malla',
'manda',
'manji',
'marek',
'maria',
'marie',
'marti',
'masar',
'masic',
'masur',
'matas',
'matos',
'maute',
'mayer',
'mayot',
'mazur',
'mazza',
'mcgee',
'mecir',
'meers',
'mejia',
'mertl',
'meyer',
'micic',
'micov',
'midon',
'miele',
'milev',
'milic',
'minin',
'mirza',
'moore',
'moret',
'morra',
'moser',
'motti',
'mujan',
'mulye',
'munar',
'munoz',
'murat',
'murta',
'myers',
'nadal',
'nadel',
'nagal',
'naito',
'naklo',
'nanda',
'nardi',
'naser',
'nawaf',
'nayar',
'nazim',
'ncube',
'nedev',
'nedic',
'nefve',
'nokic',
'norin',
'nouza',
'novac',
'novak',
'nunez',
'nuudi',
'obeid',
'obert',
'ocera',
'ofner',
'ogata',
'ogura',
'okala',
'okuno',
'olaru',
'olaya',
'oliel',
'olivo',
'olmos',
'onuma',
'opitz',
'orlov',
'ortiz',
'osaka',
'ovunc',
'ozaki',
'ozeki',
'ozgen',
'paire',
'palan',
'palma',
'panak',
'panin',
'pannu',
'panta',
'papac',
'papik',
'paris',
'parko',
'parks',
'parra',
'parry',
'pasha',
'paval',
'pavel',
'pavic',
'pecak',
'pecci',
'peckl',
'peers',
'peeva',
'pella',
'peres',
'perez',
'perin',
'petit',
'petiz',
'peyre',
'pfaff',
'pieri',
'pinto',
'piros',
'pitak',
'piter',
'pitts',
'plaza',
'pleva',
'popko',
'poppe',
'pozzi',
'prado',
'prata',
'prati',
'prehn',
'price',
'puetz',
'qamar',
'raggi',
'raina',
'rajic',
'rakic',
'raoux',
'rawat',
'reami',
'recci',
'recek',
'reddy',
'reese',
'repac',
'reyes',
'rezai',
'ricca',
'ricci',
'riedi',
'rifqi',
'riley',
'rioux',
'riske',
'rivas',
'robbe',
'rocha',
'rojas',
'rojer',
'romeo',
'rosca',
'rosol',
'rossi',
'royer',
'rubin',
'rubio',
'ruzic',
'ryser',
'sabry',
'sacco',
'sadiq',
'safin',
'sahin',
'saigo',
'saito',
'sakai',
'samir',
'saner',
'sarap',
'sarno',
'sauer',
'scala',
'scott',
'sebov',
'senli',
'seppi',
'shaik',
'shane',
'shang',
'sheng',
'shyla',
'silva',
'simic',
'simon',
'singh',
'sinha',
'sisam',
'sitak',
'skoch',
'smith',
'smits',
'smola',
'soeda',
'sokal',
'sorgi',
'souri',
'sousa',
'souza',
'stara',
'stebe',
'steur',
'stone',
'story',
'szabo',
'tabur',
'tadic',
'tahan',
'talbi',
'tamar',
'tange',
'tapia',
'tashi',
'tecau',
'temin',
'temov',
'tenti',
'tessa',
'thiem',
'thinh',
'thour',
'tiron',
'tizon',
'tokac',
'tomac',
'toman',
'tomas',
'tomic',
'touly',
'tracy',
'tseng',
'turco',
'tybar',
'tyler',
'ungur',
'ureke',
'urrea',
'urzua',
'vaise',
'vance',
'vanni',
'vanta',
'vasic',
'vecic',
'veger',
'vekic',
'velcz',
'velik',
'venus',
'verno',
'vidak',
'vinci',
'viola',
'vocel',
'volpi',
'voros',
'vujic',
'vukic',
'walch',
'weber',
'welsh',
'welte',
'wolff',
'wurth',
'xilas',
'yadav',
'yamao',
'yapur',
'yaron',
'yasar',
'yepes',
'yeung',
'yoruk',
'yosef',
'young',
'yulik',
'yunis',
'zekic',
'zelic',
'zemel',
'zgola',
'zhang',
'zheng',
'zsiga',
'zugic',
'zukas']


export const getValidGuessListByLength = (length: number) => {
  if(length === 5){return VALID_GUESSES_5};
  if(length === 6){return VALID_GUESSES_6};
  if(length === 7){return VALID_GUESSES_7};
  return []
}
