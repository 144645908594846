export const WORDS_7 = [
  'alcaraz',
  'andujar',
  'bertens',
  'brengle',
  'cirstea',
  'collins',
  'federer',
  'fognini',
  'gasquet',
  'goerges',
  'golubic',
  'humbert',
  'hurkacz',
  'ivashka',
  'johnson',
  'koepfer',
  'kostyuk',
  'kovinic',
  'kvitova',
  'lajovic',
  'lehecka',
  'linette',
  'mertens',
  'millman',
  'monfils',
  'muchova',
  'musetti',
  'paolini',
  'popyrin',
  'sakkari',
  'swiatek',
  'udvardy'
  ]
  
  export const WORDS_6 = ['badosa',
  'bagnis',
  'bencic',
  'bogdan',
  'bondar',
  'bublik',
  'cornet',
  'cressy',
  'garcia',
  'gaston',
  'giorgi',
  'goffin',
  'harris',
  'jabeur',
  'kanepi',
  'kerber',
  'konjuh',
  'kucova',
  'martic',
  'minnen',
  'molcan',
  'murray',
  'norrie',
  'opelka',
  'osorio',
  'pegula',
  'rogers',
  'rublev',
  'sharma',
  'sherif',
  'sinner',
  'sonego',
  'struff',
  'tauson',
  'tiafoe',
  'vesely',
  'zverev']
  
  export const WORDS_5 = ['barty',
  'bonzi',
  'burel',
  'cilic',
  'coria',
  'davis',
  'djere',
  'dodin',
  'evans',
  'frech',
  'fritz',
  'garin',
  'gauff',
  'giron',
  'halep',
  'isner',
  'juvan',
  'kenin',
  'korda',
  'munar',
  'nadal',
  'osaka',
  'paire',
  'pella',
  'riske',
  'sousa',
  'thiem',
  'zhang',
  'zheng']

export const getWordListByLength = (length: number) => {
  if(length === 5){return WORDS_5};
  if(length === 6){return WORDS_6};
  if(length === 7){return WORDS_7};
  return []
}