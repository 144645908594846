export const getPlayer = (name: string) => {
    name = name.toLowerCase()
    var result = PLAYERS_5.find(player => {
        return player.last_name === name
      })
    if(result != null){
        return result;
    }
    result = PLAYERS_6.find(player => {
        return player.last_name === name
      })
    if(result != null){
        return result;
    }
    result = PLAYERS_7.find(player => {
        return player.last_name === name
      })
    if(result != null){
        return result;
    }
    return {
        "last_name": "error",
        "gender": "error",
        "country": "error",
        "first_initial": "error"
      };
}

const PLAYERS_5 = [
  {
    "last_name": "barty",
    "gender": "female",
    "country": "AU",
    "first_initial": "A"
  },
  {
    "last_name": "bonzi",
    "gender": "male",
    "country": "FR",
    "first_initial": "B"
  },
  {
    "last_name": "burel",
    "gender": "female",
    "country": "FR",
    "first_initial": "C"
  },
  {
    "last_name": "cilic",
    "gender": "male",
    "country": "HR",
    "first_initial": "M"
  },
  {
    "last_name": "coria",
    "gender": "male",
    "country": "AR",
    "first_initial": "F"
  },
  {
    "last_name": "davis",
    "gender": "female",
    "country": "US",
    "first_initial": "L"
  },
  {
    "last_name": "djere",
    "gender": "male",
    "country": "RS",
    "first_initial": "L"
  },
  {
    "last_name": "dodin",
    "gender": "female",
    "country": "FR",
    "first_initial": "O"
  },
  {
    "last_name": "evans",
    "gender": "male",
    "country": "GB",
    "first_initial": "D"
  },
  {
    "last_name": "frech",
    "gender": "female",
    "country": "PL",
    "first_initial": "M"
  },
  {
    "last_name": "fritz",
    "gender": "male",
    "country": "US",
    "first_initial": "T"
  },
  {
    "last_name": "garin",
    "gender": "male",
    "country": "CL",
    "first_initial": "C"
  },
  {
    "last_name": "gauff",
    "gender": "female",
    "country": "US",
    "first_initial": "C"
  },
  {
    "last_name": "giron",
    "gender": "male",
    "country": "US",
    "first_initial": "M"
  },
  {
    "last_name": "halep",
    "gender": "female",
    "country": "RO",
    "first_initial": "S"
  },
  {
    "last_name": "isner",
    "gender": "male",
    "country": "US",
    "first_initial": "J"
  },
  {
    "last_name": "juvan",
    "gender": "female",
    "country": "SI",
    "first_initial": "K"
  },
  {
    "last_name": "kenin",
    "gender": "female",
    "country": "US",
    "first_initial": "S"
  },
  {
    "last_name": "korda",
    "gender": "male",
    "country": "US",
    "first_initial": "S"
  },
  {
    "last_name": "munar",
    "gender": "male",
    "country": "ES",
    "first_initial": "J"
  },
  {
    "last_name": "nadal",
    "gender": "male",
    "country": "ES",
    "first_initial": "R"
  },
  {
    "last_name": "osaka",
    "gender": "female",
    "country": "JP",
    "first_initial": "N"
  },
  {
    "last_name": "paire",
    "gender": "male",
    "country": "FR",
    "first_initial": "B"
  },
  {
    "last_name": "pella",
    "gender": "male",
    "country": "AR",
    "first_initial": "G"
  },
  {
    "last_name": "riske",
    "gender": "female",
    "country": "US",
    "first_initial": "A"
  },
  {
    "last_name": "sousa",
    "gender": "male",
    "country": "PT",
    "first_initial": "J"
  },
  {
    "last_name": "thiem",
    "gender": "male",
    "country": "AT",
    "first_initial": "D"
  },
  {
    "last_name": "zhang",
    "gender": "female",
    "country": "CN",
    "first_initial": "S"
  },
  {
    "last_name": "zheng",
    "gender": "female",
    "country": "CN",
    "first_initial": "Q"
  }
]

const PLAYERS_6 = [
  {
    "last_name": "badosa",
    "gender": "female",
    "country": "ES",
    "first_initial": "P"
  },
  {
    "last_name": "bagnis",
    "gender": "male",
    "country": "AR",
    "first_initial": "F"
  },
  {
    "last_name": "bencic",
    "gender": "female",
    "country": "CH",
    "first_initial": "B"
  },
  {
    "last_name": "bogdan",
    "gender": "female",
    "country": "RO",
    "first_initial": "A"
  },
  {
    "last_name": "bondar",
    "gender": "female",
    "country": "HU",
    "first_initial": "A"
  },
  {
    "last_name": "bublik",
    "gender": "male",
    "country": "KZ",
    "first_initial": "A"
  },
  {
    "last_name": "cornet",
    "gender": "female",
    "country": "FR",
    "first_initial": "A"
  },
  {
    "last_name": "cressy",
    "gender": "male",
    "country": "US",
    "first_initial": "M"
  },
  {
    "last_name": "garcia",
    "gender": "female",
    "country": "FR",
    "first_initial": "C"
  },
  {
    "last_name": "gaston",
    "gender": "male",
    "country": "FR",
    "first_initial": "H"
  },
  {
    "last_name": "giorgi",
    "gender": "female",
    "country": "IT",
    "first_initial": "C"
  },
  {
    "last_name": "goffin",
    "gender": "male",
    "country": "BE",
    "first_initial": "D"
  },
  {
    "last_name": "harris",
    "gender": "male",
    "country": "ZA",
    "first_initial": "L"
  },
  {
    "last_name": "jabeur",
    "gender": "female",
    "country": "TN",
    "first_initial": "O"
  },
  {
    "last_name": "kanepi",
    "gender": "female",
    "country": "EE",
    "first_initial": "K"
  },
  {
    "last_name": "kerber",
    "gender": "female",
    "country": "DE",
    "first_initial": "A"
  },
  {
    "last_name": "konjuh",
    "gender": "female",
    "country": "HR",
    "first_initial": "A"
  },
  {
    "last_name": "kucova",
    "gender": "female",
    "country": "SK",
    "first_initial": "K"
  },
  {
    "last_name": "martic",
    "gender": "female",
    "country": "HR",
    "first_initial": "P"
  },
  {
    "last_name": "minnen",
    "gender": "female",
    "country": "BE",
    "first_initial": "G"
  },
  {
    "last_name": "molcan",
    "gender": "male",
    "country": "SK",
    "first_initial": "A"
  },
  {
    "last_name": "murray",
    "gender": "male",
    "country": "GB",
    "first_initial": "A"
  },
  {
    "last_name": "norrie",
    "gender": "male",
    "country": "GB",
    "first_initial": "C"
  },
  {
    "last_name": "opelka",
    "gender": "male",
    "country": "US",
    "first_initial": "R"
  },
  {
    "last_name": "osorio",
    "gender": "female",
    "country": "CO",
    "first_initial": "C"
  },
  {
    "last_name": "pegula",
    "gender": "female",
    "country": "US",
    "first_initial": "J"
  },
  {
    "last_name": "rogers",
    "gender": "female",
    "country": "US",
    "first_initial": "S"
  },
  {
    "last_name": "rublev",
    "gender": "male",
    "country": "RU",
    "first_initial": "A"
  },
  {
    "last_name": "sharma",
    "gender": "female",
    "country": "AU",
    "first_initial": "A"
  },
  {
    "last_name": "sherif",
    "gender": "female",
    "country": "EG",
    "first_initial": "M"
  },
  {
    "last_name": "sinner",
    "gender": "male",
    "country": "IT",
    "first_initial": "J"
  },
  {
    "last_name": "sonego",
    "gender": "male",
    "country": "IT",
    "first_initial": "L"
  },
  {
    "last_name": "struff",
    "gender": "male",
    "country": "DE",
    "first_initial": "J"
  },
  {
    "last_name": "tauson",
    "gender": "female",
    "country": "DK",
    "first_initial": "C"
  },
  {
    "last_name": "tiafoe",
    "gender": "male",
    "country": "US",
    "first_initial": "F"
  },
  {
    "last_name": "vesely",
    "gender": "male",
    "country": "CZ",
    "first_initial": "J"
  },
  {
    "last_name": "zverev",
    "gender": "male",
    "country": "DE",
    "first_initial": "A"
  }
]

const PLAYERS_7 = [
  {
    "last_name": "alcaraz",
    "gender": "male",
    "country": "ES",
    "first_initial": "C"
  },
  {
    "last_name": "andujar",
    "gender": "male",
    "country": "ES",
    "first_initial": "P"
  },
  {
    "last_name": "bertens",
    "gender": "female",
    "country": "NL",
    "first_initial": "K"
  },
  {
    "last_name": "brengle",
    "gender": "female",
    "country": "US",
    "first_initial": "M"
  },
  {
    "last_name": "cirstea",
    "gender": "female",
    "country": "RO",
    "first_initial": "S"
  },
  {
    "last_name": "collins",
    "gender": "female",
    "country": "US",
    "first_initial": "D"
  },
  {
    "last_name": "federer",
    "gender": "male",
    "country": "CH",
    "first_initial": "R"
  },
  {
    "last_name": "fognini",
    "gender": "male",
    "country": "IT",
    "first_initial": "F"
  },
  {
    "last_name": "gasquet",
    "gender": "male",
    "country": "FR",
    "first_initial": "R"
  },
  {
    "last_name": "goerges",
    "gender": "female",
    "country": "DE",
    "first_initial": "J"
  },
  {
    "last_name": "golubic",
    "gender": "female",
    "country": "CH",
    "first_initial": "V"
  },
  {
    "last_name": "humbert",
    "gender": "male",
    "country": "FR",
    "first_initial": "U"
  },
  {
    "last_name": "hurkacz",
    "gender": "male",
    "country": "PL",
    "first_initial": "H"
  },
  {
    "last_name": "ivashka",
    "gender": "male",
    "country": "BY",
    "first_initial": "I"
  },
  {
    "last_name": "johnson",
    "gender": "male",
    "country": "US",
    "first_initial": "S"
  },
  {
    "last_name": "koepfer",
    "gender": "male",
    "country": "DE",
    "first_initial": "D"
  },
  {
    "last_name": "kostyuk",
    "gender": "female",
    "country": "UA",
    "first_initial": "M"
  },
  {
    "last_name": "kovinic",
    "gender": "female",
    "country": "ME",
    "first_initial": "D"
  },
  {
    "last_name": "kvitova",
    "gender": "female",
    "country": "CZ",
    "first_initial": "P"
  },
  {
    "last_name": "lajovic",
    "gender": "male",
    "country": "RS",
    "first_initial": "D"
  },
  {
    "last_name": "lehecka",
    "gender": "male",
    "country": "CZ",
    "first_initial": "J"
  },
  {
    "last_name": "linette",
    "gender": "female",
    "country": "PL",
    "first_initial": "M"
  },
  {
    "last_name": "mertens",
    "gender": "female",
    "country": "BE",
    "first_initial": "E"
  },
  {
    "last_name": "millman",
    "gender": "male",
    "country": "AU",
    "first_initial": "J"
  },
  {
    "last_name": "monfils",
    "gender": "male",
    "country": "FR",
    "first_initial": "G"
  },
  {
    "last_name": "muchova",
    "gender": "female",
    "country": "CZ",
    "first_initial": "K"
  },
  {
    "last_name": "musetti",
    "gender": "male",
    "country": "IT",
    "first_initial": "L"
  },
  {
    "last_name": "paolini",
    "gender": "female",
    "country": "IT",
    "first_initial": "J"
  },
  {
    "last_name": "popyrin",
    "gender": "male",
    "country": "AU",
    "first_initial": "A"
  },
  {
    "last_name": "sakkari",
    "gender": "female",
    "country": "GR",
    "first_initial": "M"
  },
  {
    "last_name": "swiatek",
    "gender": "female",
    "country": "PL",
    "first_initial": "I"
  },
  {
    "last_name": "udvardy",
    "gender": "female",
    "country": "HU",
    "first_initial": "P"
  }
]